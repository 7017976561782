import React from "react";
import aboutImage2 from "../../images/1981-JCI_President.jpg";
import sectionIcon from "../../images/section-icon.png";
const WhyJci = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={aboutImage2} alt="" />
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Know the Foundation</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries.
                                </p>
                                <p className="section__desc">
                                    The funds donated to the foundation, are invested in training, financial help through scholarship to school children who are economically deprived.
                                </p>
                                <p className="section__desc">
                                    Donations to the Foundation remain in an interestbearing account and the interest generated on such gifts is allotted for JCI India programs.
                                </p>
                                <p className="section__desc">
                                    Contributions are exempted u/s 80G & 12A of the income tax act
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Who can donate?</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Any member
                                    Former member
                                    Non member
                                    JCI Senator
                                    Local Organization
                                    National Organization,
                                    Friend,
                                    Philanthropist
                                    or Corporation is invited to donate to JCI India Foundation.
                                    Your donation is utilized for benefits of the needy students through scholarship
                                    Every educated person is not rich but almost every educated person has a job and way out of poverty. So education is fundamental solution to poverty
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5">
                        <div className="about-semi-img">
                            <img src={aboutImage2} alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default WhyJci;
